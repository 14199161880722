<template>
  <div class="category-modal" v-if="isShow" ref="modal">
    <div class="modal__overlay" @click="onCloseEmit">
      <button class="modal__close">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
          <path
            d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path>
        </svg>
      </button>
    </div>
    <div class="modal__content">
      <div class="title text-20-800">{{$t('selectCategory')}}</div>

      <base-select
        :options="categoriesListComputed"
        :value="selectedCategory"
        :placeholder="$t('searchCategory')"
        :style="{ marginBottom: '20px' }"
        @input="onSelectCategory"
        @open="onOpen"
        @search="searchCategory"
      >
        <template #footer>
          <li ref="load" class="loader" v-show="nasNextListPage">
            {{ $t('loadCategories') }}
          </li>
        </template>
      </base-select>

      <div class="categories" ref="categories">
        <div class="categories__column">
          <div class="category__item"
               v-for="category in categories"
               :key="category.id"
               :class="selected[0] === category ? 'active' : ''"
               @click="onSelectSubCategory(category, 0)">

            <span>{{ category.title }}</span>

            <svg class="svg-arrow" v-if="!category.isFinalCategory && category.children" width="4" height="8"
                 viewBox="0 0 4 8" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M4 4L0 8L-3.49691e-07 0L4 4Z" />
            </svg>

          </div>
        </div>

        <div class="categories__column" v-for="(column, depthIndex) in childCategories" :key="depthIndex">
          <div class="category__item"
               v-for="category in column"
               :key="category.id"
               :class="selected[depthIndex + 1] === category ? 'active' : ''"
               @click="onSelectSubCategory(category, depthIndex + 1)">

            <span>{{ category.title }}</span>

            <svg class="svg-arrow" v-if="!category.isFinalCategory" width="4" height="8" viewBox="0 0 4 8" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M4 4L0 8L-3.49691e-07 0L4 4Z" />
            </svg>

          </div>
        </div>

      </div>
      <div class="modal__footer">
        <button class="button warn-button" @click="onCloseEmit">{{$t('cancel')}}</button>
        <button class="button base-button" @click="onSubmit" :disabled="!validSelectedCategory">{{$t('selectCategory')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import api from '@/api/api'
import debounce from 'lodash.debounce'
import BaseSelect from 'smarket-ui/src/components/select/BaseSelect'

const flatCategories = (categories) => {
  const output = []
  const getChildren = (items) => {
    items.forEach(item => {
      const isHasChildren = !!item.children
      if (isHasChildren) {
        getChildren(item.children)
        output.push(item)
      } else {
        output.push(item)
      }
    })
  }
  getChildren(categories)
  return output
}

function createParentTree (id, categories, tree = []) {
  const [ category ] = categories.filter(c => c.id === id)
  if (category) {
    tree.push(category)
    if (category.parentId) {
      return createParentTree(category.parentId, categories, tree)
    }
  }
  return tree.reverse()
}

export default {
  name: 'SearchCategoryModal',
  components: {
    BaseSelect,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    categoryId: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      isMounted: false,
      childCategories: [],
      selected: [],
      flattedCategories: [],
      searchField: null,
      searchOptions: [],
      selectedCategory: null,
      observer: null,
      query: '',
      page: 1,
      totalPages: 1,
      perPage: 20,
    }
  },
  computed: {
    ...mapState({
      categories: state => state.products.categories,
    }),
    ...mapGetters([
      'locale',
    ]),
    lastSelected () {
      const { length } = this.selected
      return this.selected[length - 1] || null
    },
    validSelectedCategory () {
      if (!this.lastSelected) {
        return false
      }
      return this.lastSelected.isFinalCategory && !this.lastSelected.children
    },
    categoriesListComputed () {
      return this.searchOptions.map(category => {
        category.label = category.full_path
        return category
      })
    },
    nasNextListPage () {
      return this.page < this.totalPages
    },
    searchParams () {
      return {
        q: this.query,
        language: this.locale,
        page: this.page,
        perPage: this.perPage,
      }
    },
  },
  methods: {
    onCloseEmit () {
      this.$emit('onClose')
    },
    onSubmit () {
      this.$store.commit('showHintNotification', {
        type: 'SUCCESS',
        title: this.$t('categorySelected'),
        description: null,
      })
      this.$emit('onSubmit', this.lastSelected)
    },
    onSelectSubCategory (category, depthIndex) {
      this.childCategories = this.childCategories.slice(0, depthIndex)
      this.selected = this.selected.slice(0, depthIndex)

      this.childCategories.push(category.children)
      this.selected[depthIndex] = category
    },
    onSelectCategory (value) {
      this.childCategories = []
      this.selected = []

      if (!value) {
        this.selectedCategory = value
        return
      }

      this.selectedCategory = value

      const tree = createParentTree(value.id, this.flattedCategories)

      tree.forEach((selectedCategory) => {
        this.selected.push(selectedCategory)
        this.childCategories.push(selectedCategory.children)
      })
      setTimeout(() => {
        setTimeout(() => {
          this.scrollingToSelectedCategories()
        })
      })
    },
    computeCategoriesWrapperOffset () {
      const ref = this.$refs.categories
      return ref ? ref.getBoundingClientRect().top : 0
    },
    scrollingToSelectedCategories () {
      const modal = this.$refs.modal
      if (modal) {
        const wrapperOffset = this.computeCategoriesWrapperOffset()
        const columnsRef = modal.querySelectorAll(`.categories__column`)

        columnsRef.forEach(col => {
          col.scrollTop = 0
          const activeItem = col.querySelector('.category__item.active')
          if (activeItem) {
            col.scrollTop = activeItem.getBoundingClientRect().top - wrapperOffset
          }
        })
      }
    },
    // Public method
    reset () {
      this.selectedCategory = null
      this.selected = []
      this.childCategories = []
    },
    async infiniteScroll ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.page += 1
        const categoriesListData = await api.getCategoriesList(this.searchParams)
        this.searchOptions.push(...categoriesListData.data.items)
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },

    async onOpen () {
      if (this.nasNextListPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },

    searchCategory: debounce(async function (query) {
      this.query = query
      this.page = 1

      const categoriesListData = await api.getCategoriesList(this.searchParams)

      this.searchOptions = categoriesListData.data.items
      this.totalPages = categoriesListData.data.pagination.pages
    }, 1000),
  },
  watch: {
    isShow: {
      immediate: true,
      handler: function onPaginationChange (show) {
        if (show) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.removeAttribute('style')
        }
      },
    },
  },
  async mounted () {
    await this.$store.dispatch('getAllCategories')
    const categoriesListData = await api.getCategoriesList(this.searchParams)
    this.isMounted = true
    this.searchOptions = categoriesListData.data.items
    this.totalPages = categoriesListData.data.pagination ? categoriesListData.data.pagination.pages : 1
    this.observer = new IntersectionObserver(this.infiniteScroll)
    this.flattedCategories = flatCategories(this.categories)

    if (this.categoryId) {
      const [ selected ] = this.flattedCategories.filter(cat => cat.id === this.categoryId)
      if (selected) {
        this.onSelectCategory(selected)
      }
    }
  },

  beforeDestroy () {
    if (this.observer && this.observer.disconnect) this.observer.disconnect()
  },
}
</script>

<style lang="scss" scoped>
.category-modal {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: auto;
}

.modal {
  &__overlay {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  &__close {
    border: none;
    background-color: transparent;
    position: absolute;
    left: 10px;
    top: 20px;
    fill: #E5E5E5;
    outline: none;
    cursor: pointer;
  }

  &__content {
    position: relative;
    z-index: 1;

    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;
    background-color: #fff;
    margin-left: 40px;
    box-sizing: border-box;

    height: 100%;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    h2 {
      padding: 20px 20px 20px 0;
      margin-bottom: 20px;
      border-bottom: 1px solid #E5E5E5;
      font-weight: normal;
      font-size: 16px;
    }
  }

  &__footer {
    height: 80px;
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.25);
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px;
    @include flex(row, flex-end, center);

    button {
      &:last-child {
        margin-left: 20px;
      }
    }
  }

  &__field {
    @include flex;
    position: relative;

    margin-bottom: 20px;
    height: 48px;
    width: 100%;

    border: 1px solid #E5E5E5;
    box-sizing: border-box;

    img {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      width: 17px;
      height: 17px;
    }

    input {
      width: 100%;
      border: none;
      padding-left: 45px;
    }
  }
}

.categories {
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex: 1;
  @include flex(row, flex-start, flex-start);

  &__column {
    overflow-y: scroll;
    height: 100%;
    padding-bottom: 20px;
    padding-right: 10px;
    flex: none;

    &:not(:first-child) {
      padding-left: 10px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background: #fff;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #E5E5E5;
      border-radius: 5px;
    }
  }
}

.category {
  &__item {
    position: relative;
    padding: 10px 5px;
    cursor: pointer;

    @include flex(row, space-between, center);

    font-size: 14px;
    font-weight: bold;
    width: 240px;
    margin: 0;

    border-top: 1px solid #E5E5E5;
    border-left: 3px solid transparent;
    transition: .4s ease all;

    .svg-arrow {
      fill: #2E2E2E;
    }

    &:hover,
    &.active {
      border-left: 3px solid $color-primary;
      color: $color-primary;

      .svg-arrow {
        fill: $color-primary;
      }
    }

    &:last-child {
      border-bottom: 1px solid #E5E5E5;
    }
  }
}
.title {
  margin: 16px 0;
}
</style>
