<template>
  <button class="close-btn" type="button" @click="$emit('onClick')">
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="1.70711" y1="1.29289" x2="12.7071" y2="12.2929" stroke-width="2" />
      <line y1="-1" x2="15.5563" y2="-1" transform="matrix(-0.707107 0.707107 0.707107 0.707107 13 2)" stroke-width="2" />
    </svg>
  </button>
</template>

<style lang="scss" scoped>
.close-btn {
  @include flex(row, center, center);
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  line {
    transition: .4s ease all;
    stroke: $color-line
  }

  &:hover {
    line {
      stroke: #333
    }
  }
}
</style>
